import UsageService from '@/services/common/UsageService';

const usageServiceInstance = new UsageService();

export const getSecurityLogs = async({ commit }: {commit: Function}, payload: any) => {
    commit('isFetching', true);
    const securityLogs = await usageServiceInstance.getSecurityLogs(payload.organisationId);
    commit('securityLogs', securityLogs);
    commit('isFetching', false);
}

export const getSummarySecurityLogs = async({ commit }: {commit: Function}, payload: any) => {
    commit('isFetching', true);
    const summarySecurityLogs = await usageServiceInstance.getSummarySecurityLogs(payload.selectedMonth);
    commit('summarySecurityLogs', summarySecurityLogs);
    commit('isFetching', false);
}