import { IUserLog } from '@/domain/common'

export const isFetching = (state: any, isFetching: boolean) => {
    state.isFetching = isFetching;
}

export const securityLogs = (state: any, securityLogs: IUserLog[]) => {
    state.securityLogs = securityLogs;
}

export const summarySecurityLogs = (state: any, summarySecurityLogs: []) => {
    state.summarySecurityLogs = summarySecurityLogs;
}

