import Vue from 'vue'
import { createStore } from 'vuex'

// Modules
import user from './common/user'
import energyOrganisation from './energy/organisation'
import bankOrganisation from './banking/organisation'
import customer from './common/customer'
import bankIndustry from './banking/industry'
import energyIndustry from './energy/industry'
import reports from './energy/reports'
import roadmaps from './common/roadmaps'
import usage from './common/usage'
import { IFetchable, IUserProfile } from '@/domain/common';
import { UserProfile } from '@/domain/UserProfile';

class State {
    // Sign in params
    public showSecure: boolean = false
    public showSignIn: boolean = true
    public postLoginRoute: string = '/landing'
    public $auth: any
    public apiBase: string = ''

    public currentUser: IUserProfile & IFetchable<IUserProfile> = new UserProfile()

    public newCreateHolder: any = {}
}

const store = createStore({
    state: new State(),

    mutations: {
        logout(state:State) {
            state.currentUser = new UserProfile()
        },
        loginStart(state:State) {
        },
        loginComplete(state:State) {
            state.currentUser = new UserProfile()
            if (state.$auth.isAuthenticated) {
                state.currentUser.fetch();
            }
        }
    },
    actions: {
    },
    modules: {
        user,
        customer,
        reports,
        roadmaps,
        usage,
        bankOrganisation,
        energyOrganisation,
        bankIndustry,
        energyIndustry
    }
})

export default store
