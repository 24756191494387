<template>
    <div id="app">
        <div class="d-flex">
            <div id="main-side-nav" v-if="showSecure">
                <div class="row mb-2 mt-2">
                    <div class="col d-flex justify-content-center">
                        <router-link to="/landing">
                            <img src="/img/brand/pc-dark.png" class="main-side-nav-img">
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Manage Customers">
                        <router-link to="/customers">
                            <TeamOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Manage Organisations">
                        <router-link to="/organisations">
                            <BankOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Manage Users">
                        <router-link to="/users">
                            <UserOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Manage Holders">
                        <router-link to="/industries">
                            <DatabaseOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Admin Reports">
                        <router-link to="/reports">
                            <BarsOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Roadmapping">
                        <router-link to="/roadmap">
                            <ForkOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col d-flex justify-content-center" title="Usage">
                        <router-link to="/usage">
                            <StockOutlined class="main-side-nav-icon"/>
                        </router-link>
                    </div>
                </div>
            </div>
            <div id="main-app-content" :class="{ 'main-full-width': !showSecure }">
                <router-view name="header"></router-view>
                <main id="app-main">
                    <router-view/>
                </main>
                <notifications group="app-notifications" position="bottom right"/>
                <router-view name="footer"></router-view>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import {
    BankOutlined,
    UserOutlined,
    TeamOutlined,
    BarsOutlined,
    ForkOutlined,
    DatabaseOutlined,
    StockOutlined
} from '@ant-design/icons-vue';
import { defineComponent, computed } from 'vue';
import store from '@/store';

export default defineComponent({
    components: {
        BankOutlined,
        UserOutlined,
        TeamOutlined,
        BarsOutlined,
        ForkOutlined,
        DatabaseOutlined,
        StockOutlined
    },
    setup() {
        const showSecure = computed((): Boolean => {
            return store.state.showSecure
        })

        return {
            showSecure
        }
    }
})
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
        color: #42b983;
        }
    }
}
#app-main {
    min-height: 85vh;
    background: #fdfdfd;
}
#main-side-nav {
    width: 5%;
    height: 100vh;
    background: #03363D;
}
#main-app-content {
    width: 95%
}
.main-full-width {
    width: 100% !important;
}
.main-side-nav-img {
    width: auto;
    height: 50px;
}
.main-side-nav-icon {
    color: white;
    font-size: 24px;
}
</style>
