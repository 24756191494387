import * as Axios from 'axios';
import BaseService from './BaseService';import store from '@/store';
import { IUserLog } from '@/domain/common';
// import moment from 'moment';

export default class UsageService extends BaseService {
    public async getSecurityLogs(orgID: string): Promise<IUserLog[]> {
    // public async getSecurityLogs(orgID: string, recordCount: number, start: string | undefined): Promise<IUserLog[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/pc/usage/security/${orgID}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
        }
        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)

        return res.data as IUserLog[];
    }
    public async getSummarySecurityLogs(selectedMonthSummary: string): Promise<[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/pc/usage/summary`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            params: { selectedMonthSummary }
        }
        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as [];
    }
}
